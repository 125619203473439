<template>
  <div id="app" :style="{'width':windowWidth}">
    
    <router-view/>
  </div>
</template>
<script>
// import "../src/assets/js/public/rem"
export default {
  name:'App',
  data() {
    return {
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight //实时屏幕高度
    }
  },
  created() {
     window.addEventListener("scroll", this.handleScrollx, true);
     console.log(this.windowWidth)
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
  watch:{
    $router(){
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style lang="scss">
  *{
    margin: 0px;
    padding: 0px;
  }
</style>
