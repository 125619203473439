import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navnum: 0
  },
  mutations: {
    changeNum: (state, n) => {
      state.navnum = n
    }
  },
  actions: {
  },
  modules: {
  }
})
