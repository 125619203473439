import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import {Message,Carousel,CarouselItem,Icon} from 'element-ui'
// import 'lib-flexible'
import "../src/assets/js/public/rem"



Vue.config.productionTip = false;
// import { from } from 'core-js/core/array'
Vue.prototype.$axios = axios ;
axios.defaults.baseURL = '/pcapi'
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$qs=qs;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.component(Message.name,Message)
Vue.component(Carousel.name,Carousel)
Vue.component(CarouselItem.name,CarouselItem)
Vue.component(Icon.name,Icon)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


const on = Vue.prototype.$on
// 防抖处理
Vue.prototype.$on = function (event, func) {
    let timer
    let newFunc = func
    if (event === 'click') {
        newFunc = function () {
            clearTimeout(timer)
            timer = setTimeout(function () {
                func.apply(this, arguments)
            }, 5000)
        }
    }
    on.call(this, event, newFunc)
}