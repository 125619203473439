import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: ()=>import('../views/Home.vue'),
    name: 'Home',
    redirect:'/index',
    children:[
      {
        path: '/index',
        component: ()=>import('../views/Index.vue'),
        name: 'Index'
      },
      {
        path: '/product',
        component: ()=>import('../views/Product.vue'),
        name: 'Product'
      },
      {
        path: '/joinwe',
        component: ()=>import('../views/Joinwe.vue'),
        name: 'Joinwe'
      },
      {
        path: '/aboutwe',
        component: ()=>import('../views/Aboutwe.vue'),
        name: 'Aboutwe'
      },
      {
        path: '/AppDownload',
        component: ()=>import('../views/AppDownload.vue'),
        name: 'AppDownload'
      },
      {
        path: '/Test',
        component: ()=>import('../views/test.vue'),
        name: 'Test'
      }
    ]
  },
]

const router = new VueRouter({
  linkActiveClass: "active",
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
